@use "../global" as g;

/*------------------------------------------
	フッターグロナビ
------------------------------------------*/
.fnav {
	display: none;	
	
	@include g.mq('md') {
		display: block;
	}
	
	&__list {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 60px;
		margin: 0 auto;
	}

	&__item {

		> span{
			border-bottom: 1px solid #FFF;
		}
		
		> a, > span {
			display: flex;
			align-items: center;
			gap: 5px;
			font-size: 1.6rem;
			font-weight: bold;
			color: #FFF;
			padding-bottom: 5px;
			margin-bottom: 10px;

			@include g.mq('xl') {
				gap: 9px;
			}

			&::before {
				content: '';
				width: 22px;
				height: 22px;
				background-position: center;
				background-repeat: no-repeat;
			}
		}

		// グロナビアイコンの大きさ
		$gnav_icon_map: (
			home: (
				sp: (width: 18px, height: 18px),
				pc: (width: 20px, height: 20px),
			),
			company: (
				sp: (width: 18px, height: 18px),
				pc: (width: 20px, height: 20px),
			),
			work: (
				sp: (width: 20px, height: 18px),
				pc: (width: 22px, height: 20px),
			),
			environment: (
				sp: (width: 22px, height: 18px),
				pc: (width: 24px, height: 20px),
			),
			recruit: (
				sp: (width: 19px, height: 18px),
				pc: (width: 21px, height: 19px),
			),
		);
		@each $name, $val in $gnav_icon_map  {
			&.is-#{$name} {
				> a, > span {
					&::before {
						background-image: url('../img/common/gnav_icon_#{$name}_white.svg');
						background-size: g.map-deep-get($val, sp, width) g.map-deep-get($val, sp, height);
						
						@include g.mq('md') {
							background-size: g.map-deep-get($val, pc, width) g.map-deep-get($val, pc, height);
						}
					}
				}
			}
		}
	}

	&__item-lv2{
		color: #FFF;
		font-size: 1.4rem;
		letter-spacing: -0.1px;
	}
}