@charset "utf-8";

/*******************************************
	/css/style.css
*******************************************/
@use "base";
@use "module";
@use "page";

//----------- フォント -----------//

// Zen Kaku Gothic New, Montserrat, Zen Old Mincho,
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&family=Zen+Kaku+Gothic+New:wght@500;700&family=Zen+Old+Mincho:wght@600&display=swap');

// Adobe Garamond Pro
// @import url("https://use.typekit.net/cnx3wvq.css");

// 花鳥風月PBB
// https://leafscape.be/fonts/88.html
@font-face { 
	font-family: "katyo"; 
	src: url('https://cdn.leafscape.be/kacho/kachoPBB_web.woff2') format("woff2"); 
}