/*------------------------------------------
    アコーディオン
------------------------------------------*/
@use "../global" as g;

.aco {
    &__head {
        a {
            position: relative;
            display: grid;
            grid-template-columns: 1fr 50px;
            height: 50px;
            
            @include g.mq('md') {
                grid-template-columns: 1fr 64px;
                height: 64px;
            }
        }
    }
    
    &__tit {
        display: flex;
        align-items: center;
        padding: 0 20px;
        font-size: 1.8rem;
        color: g.color('base');
        border: 1px solid g.color('border');
        
        @include g.mq('md') {
            font-size: 2.2rem;
            padding: 0 30px;
        }
    }

    &__icon {
        position: relative;
        background-color: g.color('base');
        transition: 0.2s;
    
        &::before, &::after {
            content: '';
            position: absolute;
            top: 0;
            right : 0;
            bottom : 0;
            left: 0;
            width: 20px;
            height: 2px;
            margin: auto;
            background-color: #fff;
            transition: .2s;
        }
    
        &::after {
            transform: rotate(90deg);
        }
        
        &.is-active {
            &::after {
                opacity: 0;
                transform: rotate(0);
            }
        }
    }
    
    &__body-wrapper {
        overflow: hidden;
        transition: 0.4s;
        
        &.v-enter-from, &.v-leave-to {
            opacity: 0;
        }
    }
    
    &__body {
        padding: 20px 20px 35px;
        margin-top: 10px;
        border: 1px solid g.color('border');
        
        @include g.mq('md') {
            padding: 20px 30px 35px;
        }
    }
}