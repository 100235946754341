/*******************************************
    トップページ
*******************************************/
@use "sass:math";
@use "../global" as g;

#frontpage {

/*------------------------------------------
    top news
------------------------------------------*/
.top-news {
    $this: '.top-news';
    $duration: 0.4s; //アニメーション速度
    $new-color: #F63556; //NEWアイコンのカラー
    $_size: 90px;
    width: $_size;
    height: $_size;
    position: fixed;
    bottom: 30px;
    left: 30px;
    z-index: map-get(g.$z-index, 'top-news');
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid g.color('key');
    transition: $duration;

    @include g.mq('xl-max') {
        display: none;
    }
    
    &__icon {
        position: absolute;
        top: 0;
        right : 0;
        bottom : 0;
        left: 0;
        margin: auto;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: $duration;
    }

    &__label {
        font-size: 1.6rem;
        font-weight: bold;
        color: g.color('key');
    }

    &__new-icon {
        position: absolute;
        top: -3px;
        right: -4px;
        $_size: 32px;
        width: $_size;
        height: $_size;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.2rem;
        font-weight: 500;
        color: #fff;
        background-color: $new-color;
        border-radius: 50%;
    }

    &__list {
        position: relative;
        width: 728px;
        padding: 13px 35px;
        visibility: hidden;
        opacity: 0;
        transition: $duration;
    }

    &__close {
        $_size: 31px;
        width: $_size;
        height: $_size;
        position: absolute;
        top: -15px;
        left: -15px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.8rem;
        font-weight: bold;
        color: #fff;
        background-color: g.color('key');
        border-radius: 50%;
        cursor: pointer;
    }

    &__row {
        display: grid;
        grid-template-columns: 136px 1fr;
    }

    &__tit {
        display: flex;
        align-items: baseline;
        gap: 10px;
        font-weight: 500;
        color: g.color('key');
    }

    &__new-label {
        padding: 0 2px;
        font-size: 1.4rem;
        font-weight: 500;
        color: #fff;
        line-height: 1.4;
        background-color: $new-color;
        border-radius: 3px;
    }

    &__txt {
        font-size: 1.6rem;
        font-weight: 500;
    }
    
    // アクティブ状態
    &.is-active {
        box-shadow: 0 3px 20px rgba(#000, 0.16);
        border-radius: 10px;
        border: none;

        #{$this}__icon {
            visibility: hidden;
            opacity: 0;
            transition: none;
        }

        #{$this}__list {
            visibility: visible;
            opacity: 1;
            transition-delay: $duration;
        }
    }

    // アクティブ→非アクティブ
	&.is-hamburger {
        transition-delay: $duration;

		#{$this}__icon {
            transition-delay: $duration * 2;
		}
	}
}

/*------------------------------------------
    mv
------------------------------------------*/
.mv {
    position: relative;
    height: 100vh;

    &__tit {
        position: absolute;
        top: 0;
        right : 0;
        bottom : 0;
        left: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        z-index: 2;
        
        @include g.mq('md') {
            width: 80%;
        }
    }

    &__picbox{
        position: fixed;
        width: 100%;
        height: 100vh;
    }

    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(#B9CDD1, 0.43);
        mix-blend-mode: multiply;
        z-index: 1;
    }

    &__main{
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-12deg);
        width: g.get_vw(419);
        z-index: 1;
        
        @include g.mq('md') {
            width: 1303px;
        }
    }

    .inner{
        position: relative;
        height: 100%;

        &::before{
            content: "";
            position: absolute;
            top: -30px;
            left: -30px;
            width: 208px;
            height: 208px;
            z-index: 1;
            @include g.bg_c('blue');
            
            @include g.mq('md') {
                top: -365px;
                left: -90px;
                width: 722px;
                height: 722px;
            }
        }

        &::after{
            content: "";
            position: absolute;
            top: 0;
            bottom: 20px;
            left: -50px;
            width: 138px;
            height: 138px;
            margin: auto;
            z-index: 1;
            @include g.bg_c('pink');
            
            @include g.mq('md') {
                top: auto;
                bottom: -98px;
                left: -250px;
                width: 480px;
                height: 480px;
            }
        }
    }

    &__left{

        &-front,&-rear{
            position: absolute;
            bottom: 10%;
            left: -50px;
            z-index: 1;
            width: 198px;
            
            @include g.mq('md') {
                top: 50%;
                bottom: auto;
                left: -662px;
                width: 874px;
            }
        }

        &-rear{
            transform: rotate(13deg) translateY(-50%);

            @include g.mq('md') {
                top: 45%;
                left: -697px;
            }
        }

        &-front{
            transform: rotate(-5deg) translateY(-50%);

            @include g.mq('md') {
                left: -603px;
            }
        }
    }
    
    &__right-top{

        &-front,&-rear{
            position: absolute;
            top: -10px;
            right: g.get_vw(-80);
            z-index: 1;
            
            @include g.mq('md') {
                right: -400px;
            }
        }

        &-rear{
            width: 198px;
            
            @include g.mq('md') {
                width: 526px;
            }
        }

        &-front{
            transform: rotate(10deg);
            width: 216px;
            
            @include g.mq('md') {
                width: 526px;
            }
        }
    }

    &__right-bottom{

        &-front,&-rear{
            position: absolute;
            bottom: 13%;
            right: -47px; 
            width: 362px;
            
            @include g.mq('md') {
                bottom: 0;
                right: -470px; 
                width: 805px;
            }
        }

        &-front{
            transform: rotate(-10deg);
        }
    }

    &__left-sp{

        img{
            position: absolute;
            top: 100px;
            left: g.get_vw(-25);
            width: 151px;
            transform: rotate(10deg);
            z-index: 1;
        }
    }
}


/*------------------------------------------
    house
------------------------------------------*/
.house{
    position: relative;
    margin-top: -50px;
    
    @include g.mq('md') {
        margin-top: 0;
    }

    &__head{
        position: absolute;
        top: -120px;
        width: 100%;
        height: 355px;
        background: url(../img/frontpage/house_bg.webp) center / 788px no-repeat;
        z-index: 1;
        
        @include g.mq('md') {
            top: -170px;
            width: 100%;
            height: 818px;
            background: url(../img/frontpage/house_bg.webp) center / cover no-repeat;
        }
    }

    &__box{
        position: relative;
        padding-bottom: 60px;
        
        @include g.mq('md') {
            padding-bottom: 120px;
        }

        .inner{
            position: relative;
            z-index: 2;
        }
    }

    &__bg{
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(#64ABB9, 0.85);
        mix-blend-mode: multiply;
    }

    &__tit{
        position: relative;
        text-align: center;
        font-size: 2.1rem;
        color: #FFF;
        
        @include g.mq('md') {
            padding-top: 5%;
            font-size: 3.6rem;
        }

        &::after{
            content: "";
            position: absolute;
            bottom: -15px;
            left: 0;
            right: 0;
            width: 44px;
            height: 7px;
            margin: auto;
            background: url(../img/frontpage/house_tit_icon.webp) center / contain no-repeat;
            
            @include g.mq('md') {
                width: 70px;
                height: 10px;
            }
        }
    }

    &__flex{
        margin-top: 60px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        
        @include g.mq('lg') {
            margin-top: 104px;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 103px;
        }
    }

    &__pic{
        text-align: center;

        @include g.mq('sm') {
            width: 90%;
            margin: 0 auto;
        }
    }

    &__txt{
        color: #FFF;
        font-size: 1.6rem;
        line-height: 2.1;
        letter-spacing: 0;
        text-align: center;
        
        @include g.mq('md') {
            font-size: 2.4rem;
        }
        
        @include g.mq('lg') {
            text-align: left;
        }
    }

    &__btn{
        margin-top: 20px;
        color: #FFF;
        
        @include g.mq('md') {
            margin-top: 85px;
        }

        a{
            justify-content: flex-end;

            @include g.mq('sm') {
                font-size: 1.8rem;
            }
            
            @include g.mq('lg') {
                justify-content: flex-start;
            }
        }
    }
}


/*------------------------------------------
    about
------------------------------------------*/
.about{
    position: relative;
    overflow: hidden;
    background-color: #FFF;
    padding: 60px 0 104px;
    
    @include g.mq('md') {
        padding: 270px 0;
    }

    &__area{    
        @include g.mq('lg-max') {
            @include g.inner('sp');
        }
    
        @include g.mq('lg') {
            position: relative;
            display: flex;
            justify-content: end;
            padding-right: calc(g.get-inner-side(g.$inner-width-sp) - 87px);
        }
        
        @include g.mq('xl') {
            padding-right: calc(g.get-inner-side() - 87px);
        }
    }

    &__sticky-container {
        @include g.mq('lg') {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &__sticky-item {
        margin-bottom: 50px;
        
        @include g.mq('lg') {
            margin-bottom: 0;
        }
    }

    &__txtarea{
        position: relative;

        @include g.mq('lg') {
            width: min(percentage(math.div(479, 1200)), 479px);
            margin-left: g.get-inner-side(g.$inner-width-sp);
        }
        
        @include g.mq('xl') {
            margin-left: g.get-inner-side();
        }

        &::before{
            content: "";
            position: absolute;
            bottom: -1220px;
            right: -856px;
            width: 1187px;
            height: 1187px;
            margin: auto;
            @include g.bg_c('blue');
            
            @include g.mq('lg') {
                bottom: 0;
                top: 340px;
                right: -1200px;
                width: 1280px;
                height: 1280px;
            }
        }
    }

    &__txt{
        position: relative;
        z-index: 1;

        span{
            position: relative;
        }
    }

    &__bg{
        width: g.get_vw(355);
        height: g.get_vw(136);
        margin: -25px calc(50% - 50vw) 0;
        
        @include g.mq('lg') {
            width: 934px;
            height: auto;
            margin: -60px 0 0 0;
        }
    }

    &__conts {
        @include g.mq('lg') {
            width: min(percentage(math.div(617, 1200)), 617px);
            margin-left: auto;
        }
    }

    &__list{
        display: flex;
        flex-direction: column;
        gap: 26px;
        
        @include g.mq('md') {
            gap: 64px;
        }
    }

    &__item{
        width: 100%;

        a{
            position: relative;
            width: 100%;
            background: #FFF;
            display: block;
            padding: 9px 9px 27px 9px;
            box-shadow: 0px 0px 15px -5px #949494;
            
            @include g.mq('md') {
                padding: 17px 17px 56px 17px;
            }
        }

        $array_sp: (
            (108px,46px,10px), //width、height、bottom
            (105px,11px,30px),
            (120px,66px,-20px),
        );

        $num: 0;
        @each $key in $array_sp {
            $num: $num + 1;
            &:nth-of-type(#{$num}){
                a{
                    &::after{
                        content: "";
                        position: absolute;
                        bottom: nth($key, 3);
                        right: -10px;
                        width: nth($key, 1);
                        height: nth($key, 2);
                        background: url(../img/frontpage/about_line_0#{$num}.webp) center / cover no-repeat;
                    }
                }
            }
        }

        @include g.mq('md') {

            $array: (
                (213px,40px,20px), //width、height、bottom
                (215px,20px,40px),
                (222px,142px,-50px),
            );
            
            $num: 0;
            @each $key in $array {
                $num: $num + 1;
                &:nth-of-type(#{$num}){
                    a{
                        &::after{
                            content: "";
                            position: absolute;
                            bottom: nth($key, 3);
                            right: -30px;
                            width: nth($key, 1);
                            height: nth($key, 2);
                            background: url(../img/frontpage/about_line_0#{$num}.webp) center / cover no-repeat;
                        }
                    }
                }
            }
        }
    }

    &__pic{
        margin-bottom: 20px;

        img{
            width: 100%;
        }
    }

    &__tit{
        position: relative;
        font-size: 2.0rem;
        color: g.color('key');
        padding-left: 30px;
        margin-bottom: 10px;
        
        @include g.mq('md') {
            font-size: 3.2rem;
            padding-left: 47px;
        }

        &::before{
            content: "";
            position: absolute;
            width: 24px;
            height: 1px;
            background: g.color('key');
            top: 0;
            bottom: 0;
            left: -9px;
            margin: auto;
            
            @include g.mq('md') {
                width: 45px;
                left: -17px;
            }
        }
    }

    &__btn{
        padding-left: 30px;
        
        @include g.mq('md') {
            padding-left: 47px;
        }
    }
}


/*------------------------------------------
    work
------------------------------------------*/
.work{
    position: relative;
    background: g.color('bg');

    &::before{
        content: "";
        position: absolute;
        top: -47px;
        left: 0;
        width: 100%;
        height: 50px;
        background: url(../img/frontpage/work_head.webp) center / 100% 100% no-repeat;
        
        @include g.mq('md') {
            top: -228px;
            height: 229px;
        }
    }

    &__inner{
        position: relative;
        overflow: hidden;
        padding: 45px 0 g.get_vw(77);
        
        @include g.mq('md') {
            padding: 112px 0 250px;
        }
    }

    &__titbox{
        position: relative;
        z-index: 1;

        &::after{
            content: "";
            position: relative;
            display: block;
            width: 120%;
            left: -10%;
            max-width: initial;
            height: g.get_vw(141);
            background: url(../img/frontpage/work_image_icon_01_sp.webp) center / contain no-repeat;
            margin: auto;
            z-index: 1;
            
            @include g.mq('md') {
                position: absolute;
                bottom: -645px;
                left: -30%;
                width: 160%;
                height: 660px;
                background: url(../img/frontpage/work_image_icon_01_pc.webp) center / 2250px no-repeat;
            }

            @include g.mq('lg') {
                bottom: -570px;
            }
        }
    }

    &__titpic{
        position: relative;
        left: -8%;
        
        @include g.mq('md') {
            left: -30%;
            width: 1620px;
        }

        img{            
            @include g.mq('sm') {
                max-width: initial;
                width: 108%;
            }
        }
    }

    &__txtarea{       
        margin-top: 30px;
        position: relative;

        @include g.mq('md') {
            position: absolute;
            bottom: -170px;
            right: -90px;
            width: 737px;
            display: inline-block;
            padding: 90px;
            margin-top: 0; 
            background-color: g.color('bg');
        }

        @media screen and (min-width: 1142px){
            bottom: -90px;
        }
        
        &::before{
            content: "";
            position: absolute;
            top: -96px;
            right: 0;
            width: 91px;
            height: 82px;
            background: url(../img/frontpage/work_tit_icon.webp) center / contain no-repeat;
            margin: auto;
            z-index: 1;
            
            @include g.mq('md') {
                top: auto;
                bottom: -120px;
                right: auto;
                left: -60px;
                width: 251px;
                height: 226px;
            }

            @include g.mq('lg') {
                bottom: 0px;
                left: -226px;
            }
        }
    }

    &__conts{
        position: relative;
        display: flex;
        gap: 15px;
        margin-top: 20px;
        
        @include g.mq('md') {
            align-items: center;
            gap: 80px;
            margin-top: 420px;
        }
        
        @include g.mq('lg') {
            margin-top: 360px;
        }

        &::before{
            content: "";
            position: absolute;
            top: -30px;
            left: -254px;
            width: 589px;
            height: 589px;
            @include g.bg_c('blue');
            
            @include g.mq('md') {
                top: -568px;
                left: -540px;
                width: 1280px;
                height: 1280px;
            }
        }

        &::after{
            content: "";
            position: absolute;
            bottom: -160px;
            right: -180px;
            width: 275px;
            height: 275px;
            @include g.bg_c('pink');
            
            @include g.mq('md') {
                bottom: -220px;
                right: -859px;
                width: 959px;
                height: 959px;
            }
        }

        &-titbox{
            position: relative;
            text-align: center;
            margin-top: 30px;
            z-index: 2;
            
            @include g.mq('md') {
                margin-top: 0;
            }

            img{
                @include g.mq('sm') {
                    width: 40px;
                }
            }
        }
    }

    &__btn{        
        margin-top: 30px;
        visibility: hidden; //第一フェーズ用

        @include g.mq('md') {
            margin-top: 60px;
        }

        .arrow.is-02{
            @include g.mq('sm') {
                width: 45px;
                height: 45px;
            }
        }
    }
}


/*------------------------------------------
    interview(swiper)
------------------------------------------*/
@mixin mask(){
    mask-image: url(../img/frontpage/work_slider_01.webp);
    mask-repeat: no-repeat;
    mask-position: 50% 50%;
    mask-size: 100%;
    -webkit-mask-image: url(../img/frontpage/work_slider_01.webp);
    -webkit-mask-repeat: no-repeat;
    -webkit-mask-position: 50% 50%;
    -webkit-mask-size: 100%;
}

.interview{
    $this: ".interview";
    position: relative;
    z-index: 2;

    .swiper{
        width: 568px;
        padding-left: 10px;
        
        @include g.mq('md') {
            width: 1200px;
            padding-left: 15px;
        }
    }

    .swiper-slide{
        width: 180px !important;
        
        @include g.mq('md') {
            width: 347px !important;
        }
    }

    &__item{
        width: 180px;
        margin-left: 5px;
        margin-right: 18px;
        
        @include g.mq('md') {
            width: 347px;
            margin-left: 15px;
            margin-right: 60px;
        }

        a{
            display: block;
            pointer-events: none; //第一フェーズ用

            &:hover{
                opacity: 1;

                #{$this}__comment{
                    opacity: 1;
                }

                #{$this}__arrow{
                    background-color: g.color('accent');
            
                    &::before{
                        top: -5px;
                        left: calc(50% + 5px);
                        background: #FFF;
                    }
            
                    &::after{
                        top: -9px;
                        left: calc(50% + 7px);
                        border-top: 1px solid #FFF;
                        border-right: 1px solid #FFF;
                    }
                }
            }
        }
    }

    &__pic{
        position: relative;

        img{
            @include mask;
        }
    }

    &__label{
        position: absolute;
        bottom: 20px;
        left: -6px;
        background-color: g.color('key');
        padding: 5px 10px;
        font-weight: bold;
        color: #FFF;
        line-height: 1;
        z-index: 1;
        
        @include g.mq('md') {
            bottom: 40px;
            left: -15px;
            padding: 5px 20px;
        }

        .is-26{
            font-size: 1.8rem;
            
            @include g.mq('md') {
                font-size: 2.6rem;
            }
        }
        .is-20{
            font-size: 1.2rem;
            font-family: g.font("en");
            
            @include g.mq('md') {
                font-size: 2.0rem;
            }
        }
        .is-38{
            font-size: 2.0rem;
            font-family: g.font("en");
            
            @include g.mq('md') {
                font-size: 3.8rem;
            }
        }
    }

    &__comment{
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        background: rgba(#000, 0.45);
        // mix-blend-mode: multiply;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #FFF;
        text-align: center;
        opacity: 1;
        transition: .4s;

        @include mask;
        
        span{
            position: relative;
            top: 20px;
            transform: rotate(-5deg);
            font-size: 1.4rem;
            font-family: g.font('katyo');
            
            @include g.mq('md') {
                top: 40px;
                font-size: 2.9rem;
            }
        }
    }

    &__box{
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
    }

    &__job{
        display: inline-block;
        border: 1px solid g.color('border');
        background: #FFF;
        padding: 0 10px;
        border-radius: 50px;
        
        @include g.mq('sm') {
            font-size: 1.0rem;
        }
    }

    &__name{
        font-size: 1.8rem;
        font-weight: bold;
        
        @include g.mq('md') {
            font-size: 2.8rem;
        }
    }

    &__arrow{
        width: 67px;
        height: 67px;
        border-radius: 80px;
        transition: .4s;
        visibility: hidden; //第一フェーズ用
        
        @include g.mq('md') {
            width: 129px;
            height: 129px;
        }

        &::before{
            transform: translateX(-50%) rotate(-45deg);
        }

        &::after{
			top: -4px;
			left: calc(50% + 2px);
            transform: translateX(-50%);
        }
    }
}


/*------------------------------------------
    flow
------------------------------------------*/
.flow{
    $this: ".flow";
    position: relative;
    height: 200px;
    z-index: 1;
    
    @include g.mq('md') {
        height: 411px;
    }

    &::before{
        content: "";
        position: absolute;
        top: -90px;
        left: 0;
        width: 100%;
        height: g.get_vw(118);
        background: url(../img/frontpage/work_image_icon_02_sp.webp) center / cover no-repeat;
        z-index: 1;
        
        @include g.mq('md') {
            left: 0;
            top: -400px;
            width: 100%;
            height: 600px;
            background: url(../img/frontpage/work_image_icon_02_pc.webp) center / cover no-repeat;
        }
    }
    
    &::after{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #90B6E2;
        mix-blend-mode: multiply;
    }

    .swiper, swiper-container{
        z-index: 0;
    }

    .swiper-wrapper{
        transition-timing-function: linear;
    }

    .swiper-slide{
        width: 265px !important;
        
        @include g.mq('md') {
            width: 545px !important;
        }
    }

    &__box{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        .inner{
            position: relative;
            height: 100%;
        }
    }

    &__titbox{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        z-index: 1;
    }

    &__titpic{
        text-align: center;

        img{
            @include g.mq('sm') {
                width: 252px;
            }
        }
    }

    &__tit{
        font-size: 2.4rem;
        font-weight: bold;
        text-align: center;
        color: #FFF;
        
        @include g.mq('md') {
            font-size: 5.4rem;
        }
    }

    &__txt{
        font-size: 1.4rem;
        text-align: center;
        color: #FFF;
        
        @include g.mq('md') {
            font-size: 2.4rem;
        }
    }

    &__icon{
        position: absolute;
        left: 0;
        z-index: 1;
        width: 52px;
        bottom: -50px;
        
        @include g.mq('md') {
            width: 140px;
            bottom: -130px;
        }
        
        @media screen and (min-width: 1196px){
            width: 201px;
            bottom: -60px;
        }
    }

    &__btn{
        position: absolute;
        bottom: -55px;
        right: -10px;
        width: 85px;
        z-index: 1;

        @include g.mq('md') {
            width: 184px;
            bottom: -140px;
            right: 0;
        }

        @include g.mq('lg') {
            bottom: -68px;
        }

        svg{
            width: 100%;
            height: auto;
        }

        &-txtbox{
            position: absolute;
            text-align: center;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
        }

        &-txt{
            font-size: 1.4rem;
            color: #FFF;
            font-family: g.font("en");
            text-align: center;
			transition: 0.4s;
            
            @include g.mq('md') {
                font-size: 2.8rem;
            }
        }

        path{
			transition: 0.4s;
        }

        a{
            position: relative;
            display: block;

            &:hover{                

                #{$this}__arrow{
                    background-color: transparent;
            
                    &::before{
                        top: -5px;
                        left: calc(50% + 5px);
                    }
            
                    &::after{
                        top: -9px;
                        left: calc(50% + 7px);
                    }
                }
            }
        }
    }

    &__arrow{
        width: 20px;
        height: 20px;
        transition: .4s;
        background: transparent;
        margin-left: 0;
        
        @include g.mq('md') {
            width: 40px;
            height: 40px;
        }

        &::before{
            background: #FFF;
            transform: translateX(-50%) rotate(-45deg);
            
            @include g.mq('sm') {
                width: 8px;
            }
        }

        &::after{
			top: -4px;
			left: calc(50% + 2px);
            transform: translateX(-50%);
            border-top: 1px solid #FFF;
            border-right: 1px solid #FFF;
            
            @include g.mq('sm') {
                top: -2px;
                left: calc(50% + 1px);
                width: 4px;
                height: 4px;
            }
        }
    }
}


/*------------------------------------------
    environment
------------------------------------------*/
.environment{
    $this: ".environment";

    position: relative;
    overflow: hidden;
    padding: 136px 0 102px;
    background: #FFF;
    
    @include g.mq('md') {
        padding: 232px 0 280px;
    }
    
    @include g.mq('lg') {
        padding: 232px 0 616px;
    }

    &__titbox{
        position: relative;
        z-index: 1;

        @include g.mq('md') {
            display: flex;
            gap: 40px;
        }
    }

    &__tit{
        flex: 0 0 auto;
    }

    &__txt{
        line-height: 2.167;
        
        @include g.mq('md') {
            margin-top: 60px;
        }
    }

    &__conts{
        margin-top: 50px;
        
        @include g.mq('md') {
            margin-top: 80px;
        }
    }

    &__item{
        position: relative;
        
        @include g.mq('lg') {
            display: flex;
            gap: 70px;
        }

        &:not(:last-child){
            margin-bottom: 40px;
        }

        &:nth-of-type(odd){

            &::before{
                content: "";
                position: absolute;
                top: -600px;
                right: -366px;
                width: 589px;
                height: 589px;
                @include g.bg_c('blue');
                
                @include g.mq('lg') {
                    top: -1100px;
                    right: -859px;
                    width: 1280px;
                    height: 1280px;
                }
            }

            &::after{
                content: "";
                position: absolute;
                bottom: 80px;
                left: -70px;
                width: 275px;
                height: 275px;
                @include g.bg_c('pink');
                
                @include g.mq('lg') {
                    top: -183px;
                    bottom: auto;
                    left: -561px;
                    width: 959px;
                    height: 959px;
                }
            }
        }

        &:nth-of-type(even){
            @include g.mq('md') {
                flex-direction: row-reverse;
            }

            &::before{
                content: "";
                position: absolute;
                right: -114px;
                width: 275px;
                height: 275px;
                z-index: 1;
                @include g.bg_c('pink');
                
                @include g.mq('lg') {
                    bottom: -183px;
                    right: -718px;
                    width: 959px;
                    height: 959px;
                }
            }

            &::after{
                content: "";
                position: absolute;
                bottom: -250px;
                left: -174px; 
                width: 328px;
                height: 328px;
                z-index: 1;
                @include g.bg_c('blue');
                
                @include g.mq('lg') {
                    bottom: -900px;
                    left: -1000px; 
                    width: 1280px;
                    height: 1280px;
                }
            }

            #{$this}__pic{
                
                @include g.mq('sm') {
                    margin-left: 0;
                    margin-right: -5%;
                }

                @include g.mq('lg') {
                    margin-left: 0;
                    margin-right: -290px;
                }
            }

            #{$this}__btn{
                a{
                    @include g.mq('lg') {
                        justify-content: flex-end;
                    }
                }
            }
        }
    }

    &__pic{
        position: relative;
        z-index: 2;

        @include g.mq('sm') {
            max-width: initial;
            width: 105%;
            margin-left: -5%;
        }

        @include g.mq('lg') {
            margin-left: -290px;
        }

    }

    &__txtbox{
        position: relative;
        z-index: 2;
        margin-top: 20px;
        
        @include g.mq('lg') {
            margin-top: 135px;
        }
    }

    &__catchcopy{
        margin-bottom: 5px;
        font-size: 1.4rem;
        color: g.color('key');
        
        @include g.mq('md') {
            font-size: 2.4rem;
        }
    }

    &__subtit{
        font-size: 2.0rem;
        line-height: 1.528;
        font-weight: bold;

        @include g.mq('md') {
            font-size: 3.6rem;
        }
    }

    &__btn{
        margin-top: 10px;
        
        @include g.mq('md') {
            margin-top: 40px;
        }
    }

    &__arrow{
        margin-left: 0;
        border: 1px solid g.color('accent');

        @include g.mq('sm') {
            width: 39px;
            height: 39px;
            
            &::before{
                width: 10px;
            }

            &::after{
                left: calc(50% + 2px);
                width: 4px;
                height: 4px;
            }
        }
    }
}


/*------------------------------------------
    cross
------------------------------------------*/
.cross{
    $this: ".cross";
    
    position: relative;
    padding: 45px 0 0;
    background: #FFF;
    
    @include g.mq('md') {
        padding: 110px 0 0;
    }

    .inner{
        position: relative;
        z-index: 1;

        &::before{
            @include g.mq('md') {
                content: "";
                position: absolute;
                width: 328px;
                height: 328px;
                @include g.bg_c('pink');
                
                @include g.mq('md') {
                    bottom: -450px;
                    right: -718px;
                    width: 959px;
                    height: 959px;
                }
            }
        }

        &::after{
            content: "";
            position: absolute;
            left: -63px;
            width: 275px;
            height: 275px;
            @include g.bg_c('blue');
            
            @include g.mq('md') {
                bottom: -1500px;
                left: -490px;
                width: 1280px;
                height: 1280px;
            }
        }
    }

    &__titbox{
        position: relative;
        z-index: 1;
        
        @include g.mq('md') {
            padding-top: 67px;
        }
    }

    &__titpic{
        text-align: center;
    }

    &__tit{
        margin: 20px 0;
        text-align: center;
        font-size: 2.0rem;
        color: g.color('key');
        line-height: 1.417;
        font-weight: normal;
        
        @include g.mq('md') {
            margin: 80px 0 30px;
            font-size: 3.6rem;
        }
    }

    &__txt{
        width: 317px;
        margin: 0 auto 35px;
        line-height: 1.944;
        
        @include g.mq('md') {
            width: 317px;
            margin: 0 auto 57px;
        }
    }

    &__list{
        display: flex;
        flex-direction: column;
        gap: 17px;
        
        @include g.mq('md') {
            gap: 28px;
        }
    }

    &__item{
        a{
            pointer-events: none; //第一フェーズ用
        }
    }

    &__picitem{
        margin-top: 40px;

        @include g.mq('lg') {
            position: absolute;
        }

        img{
            @include g.mq('lg-max') {
                width: 100%;
            }
        }

        &:nth-child(1){
            
            @include g.mq('sm') {
                width: 106%;
                margin-left: -6%;
            }

            @include g.mq('lg') {
                top: 0;
                left: -460px;
            }
            
            @include g.mq('xl') {
                top: 0;
                left: -360px;
            }
        }
        &:nth-child(2){
            
            @include g.mq('sm') {
                width: 106%;
                margin-right: -6%;
                margin-top: 20px;
            }

            @include g.mq('lg') {
                top: 217px;
                right: -460px;
            }
            
            @include g.mq('xl') {
                top: 217px;
                right: -360px;
            }
        }
    }
}


/*------------------------------------------
    news
------------------------------------------*/
.news{
    $this: ".news";
    
    position: relative;
    padding: 42px 0 102px;
    background: g.color('bg');
    z-index: 2;
    
    @include g.mq('md') {
        padding: 42px 0 180px;
    }

    &::before{
        content: "";
        position: absolute;
        top: -47px;
        left: 0;
        width: 100%;
        height: 50px;
        background: url(../img/frontpage/news_head.webp) center / 100% 100% no-repeat;
        
        @include g.mq('md') {
            top: -228px;
            height: 229px;
        }
    }

    &__tit{
        margin-bottom: 30px;
        font-size: 3.0rem;
        color: g.color('key');
        font-weight: normal;
        
        @include g.mq('md') {
            margin-bottom: 50px;
            font-size: 6.0rem;
        }
    }

    &__conts{        
        display: flex;
        flex-direction: column;
        gap: 60px;
        
        @include g.mq('md') {
            flex-direction: row;
            gap: 63px;
        }
    }

    &__subtit{
        margin-bottom: 16px;
        font-size: 2.0rem;
        font-weight: normal;
        
        @include g.mq('md') {
            margin-bottom: 20px;
            font-size: 3.6rem;
        }
    }

    &__list{
        display: flex;
        flex-direction: column;
        gap: 25px;
        margin-bottom: 30px;
        
        @include g.mq('md') {
            gap: 35px;
            margin-bottom: 42px;
        }
    }

    &__item{
        padding-bottom: 20px;
        border-bottom: 1px solid #FFF;
        
        @include g.mq('md') {
            padding-bottom: 30px;
        }
    }

    &__date{
        font-size: 1.4rem;
        color: g.color('key');
        
        @include g.mq('md') {
            font-size: 2.4rem;
        }
    }

    &__unit{
        display: flex;
    }

    &__btn{
        flex: 0 0 auto;
        margin-top: 10px;
        margin-left: 25px;
        
        @include g.mq('md') {
            margin-left: 38px;
        }
    }
    
    &__btn-nomal{
        text-align: left;

        a{
            padding: 5px 15px;
            min-width: 153px;
            font-weight: normal;
            text-align: left;
            
            @include g.mq('sm') {
                min-width: 125px;
                font-size: 1.4rem;
            }
        }
    }

    &__arrow{
        right: 5px;
        
        @include g.mq('md') {
            right: 15px;
        }
    }
}


/*------------------------------------------
    recruit
------------------------------------------*/
.recruit{
    $this: ".recruit";    
    position: relative;

    .inner{
        position: absolute;
        height: 100%;
        top: 0;
        right: 0;
        left: 0;
        margin: auto;
        display: flex;
        align-items: center;
    }

    &__bg{
        img{
            @include g.mq('lg') {
                min-height: 390px;
                object-fit: cover;
            }
        }
    }

    &__box{
        width: 100%;
    }

    &__tit{        
        color: #FFF;

        span{
            color: #FFF;
            &::before{
                background: #FFF;
            }
        }
    }

    &__btn{
        
        @include g.mq('sm') {
            width: 100%;
            text-align: center;
        }

        a{
            position: relative;
            min-width: 95%;
            display: inline-block;
            text-align: center;
            background-color: g.color('accent');
            color: #FFF;
            font-size: 1.6rem;
            padding: 20px 35px 20px 5px;
            border-radius: 50px;
            box-shadow: 3px 3px 12px -5px #2e2e2e;
            
            @include g.mq('md') {
                min-width: 628px;
                padding: 30px 20px;
                font-size: 2.4rem;
            }

            img{
                margin-right: 10px;
                margin-top: -5px;

                @include g.mq('sm') {
                    width: 20px;
                }
            }

            path{
                transition: 0.4s;
            }

            &:hover{
                background-color: #FFF;
                color: g.color('accent');

                path{
                    fill: g.color('accent');
                }

                #{$this}__arrow{

                    &::before{
                        height: 2px;
                        background: g.color('accent');
                    }
                
                    &::after{
                        border-top: 2px solid g.color('accent');
                        border-right: 2px solid g.color('accent');
                    }

                    &.is-02{
                        background-color: transparent;
                    }
                }
            }
        }
    }

    &__arrow{
        position: absolute;
        right: 15px;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 25px;
        height: 25px;
        background-color: g.color('accent');
        
        @include g.mq('md') {
            right: 35px;
        }

        &::before{
            height: 2px;
            background: #FFF;
        }
    
        &::after{
            border-top: 2px solid #FFF;
            border-right: 2px solid #FFF;
        }  
		
		&.is-02{
			background-color: transparent;
		}      
    }

}
}

