// ブレイクポイント
$breakpoints: (
    'xs': 'screen and (max-width: 375px)',
    'sm': 'screen and (max-width: 767px)',
    'md': 'screen and (min-width: 768px)',
    'lg': 'screen and (min-width: 992px)',
    'lg-max': 'screen and (max-width: 991px)',
    'xl': 'screen and (min-width: 1320px)',
    'xl-max': 'screen and (max-width: 1319px)',
    'inner': 'screen and (min-width: 1240px)',
    'inner-max': 'screen and (max-width: 1239px)',
)!default;

// inner-width
$inner-width: 1200px;
$inner-width-small: 950px;
$inner-width-sp: 90%;

// フォント
// 出力例) font('base');
$font: (
    'base': ('Zen Kaku Gothic New', sans-serif),
    'en': ('Montserrat', sans-serif),
    'katyo': ('katyo', sans-serif),
    'mincho': ('Zen Old Mincho', serif),
);

// 色管理
// 出力例) color('base');
// 多次元mapの出力例) color('cat', 'event', 'performance');
$color: (
	'base': #333,
    'key': #0055B8,
    'accent': #EF604D,
    'sub': #FF8800,
    'bg': #EFF6FB,
    'bg-dark': #B1CCEA,
    'gray': #D9D9D9,
    'border': #EEEEEE,
);

// 重ね順管理
$z-index: (
    'recruit-btn': 1003,
    'gnav-conts': 1002,
    'gnav': 1001,
    'header': 1000,
    'gotop': 999,
    'top-news': 998,
);

//ヘッダーの高さ
$headerH: (
    'sm': 70px,
    'md': 90px
);