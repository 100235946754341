@use "../global" as g;
@use "sass:math";

/*------------------------------------------
	ボタン
------------------------------------------*/
.btn {
	a,i{
		display: flex;
		align-items: center;
		font-family: g.font("en");
		font-size: 1.4rem;
		letter-spacing: 0;
		font-style: normal;
		
		@include g.mq('md') {
			font-size: 2.1rem;
		}
	}
}