@use "../global" as g;

/*------------------------------------------
	tit
------------------------------------------*/
.tit {
	font-size: 3.2rem;
	font-weight: bold;
	line-height: 1.5;
	margin-bottom: 15px;
	
	@include g.mq('md') {
		font-size: 4.4rem;
		margin-bottom: 30px;
	}

	span {
		position: relative;
		display: block;
		font-family: g.font('en');
		color: g.color('key');
		font-size: 2.0rem;
		letter-spacing: 0;
		font-weight: bold;
		padding-left: 20px;

		@include g.mq('md') {
			font-size: 2.5rem;
		}

		&::before{
			content: "";
			position: absolute;
			width: 10px;
			height: 10px;
			left: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			border-radius: 10px;
			background-color: g.color('key');
		}
	}
}


