@use "../global" as g;
@use "sass:math";

/*------------------------------------------
	entry
------------------------------------------*/
.entry{
	position: relative;
	width: 100%;
	padding: 40px 10px 40px;
	background: g.color('bg');
	border-radius: 10px;
	box-shadow: 0 0 12px -5px #2e2e2e;
	
	@include g.mq('md') {
		width: 100%;
		padding: 66px 88px 94px;
	}

	&__box{	
		&:not(:last-of-type){
			margin-bottom: 41px;
			
			@include g.mq('md') {
				margin-bottom: 64px;
			}
		}
	}

	&__tit{
		font-size: 1.6rem;
		font-weight: bold;
		color: g.color('key');
		text-align: center;
		margin-bottom: 29px;
		
		@include g.mq('md') {
			font-size: 2.4rem;
		}
	}

	&__list{
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 8px;
		
		@include g.mq('md') {
			flex-direction: row;
			gap: 37px;
		}
	}

	&__item{
		text-align: center;
	}
}

/*------------------------------------------
	fancybtnの初期化
------------------------------------------*/
.fancybox__container{
}
.fancybox__backdrop{
	background: rgba(24, 24, 27, 0.2) !important;
}
.fancybox__content > .f-button.is-close-btn{
	width: 44px;
    height: 44px;
    border-radius: 40px;
	background: g.color('key');
	opacity: 1;
	right: -22px;
    top: -22px;
}
.fancybox__content > .f-button.is-close-btn:hover{
	opacity: 0.75;
}