/*******************************************
    数字でみるニチイケアパレス
*******************************************/
@use "sass:math";
@use "../global" as g;

#data360 {


/*------------------------------------------
    conts
------------------------------------------*/
.conts{
    padding-top: 30px;
    
    @include g.mq('md') {
        padding-top: 0;
    }
    
    &__head{
        margin-bottom: g.get_vw(-24);
        
        @include g.mq('md') {
            margin-bottom: -33px;
        }
    }
    
    &__area{
        padding-bottom: 60px;
        background: g.color('bg');
    }

    &__catchcopy{
        padding-top: 50px;
        text-align: center;
        font-size: 2.0rem;
        color: g.color('key');
        
        @include g.mq('md') {
            font-size: 2.8rem;
        }
    }
}

/*------------------------------------------
    sec
------------------------------------------*/
.sec{

    &__tit{
        margin-top: 48px;
        margin-bottom: 30px;
        text-align: center;
        font-size: 3.2rem;
        font-weight: bold;
        line-height: 1.4;
        
        @include g.mq('md') {
            margin-top: 100px;
            font-size: 4.4rem;
        }
        
        span{
            display: block;
            font-size: 2.0rem;
            font-family: g.font('en');
            color: g.color('key');
            
            @include g.mq('md') {
                font-size: 2.5rem;
            }
        }
    }

}

/*------------------------------------------
    box
------------------------------------------*/
.box{

    &__list{
        display: flex;
        flex-wrap: wrap;
        gap: 19px;
        
        @include g.mq('md') {
            justify-content: center;
            gap: 10px;
        }
    }
    
    &__item{
        position: relative;
        padding: 53px 26px 26px;
        background: #FFF;
        width: 100%;
        
        @include g.mq('md') {
            padding: 82px 50px 40px;
        }

        &.is-w490{

            @include g.mq('inner') {
                max-width: 490px;
            }
        }

        &.is-w699{

            @include g.mq('inner') {
                max-width: 699px;
            }
        }

        &.is-w707{

            @include g.mq('inner') {
                max-width: 707px;
            }
        }
    }

    &__tit{
        margin-bottom: 20px;
        line-height: 1.333;
        font-size: 2.4rem;
        font-weight: bold;
        text-align: center;
        
        @include g.mq('md') {
            margin-bottom: 40px;
            font-size: 3.2rem;
        }

        .is-circle{
            position: relative;
            display: inline-block;  
            z-index: 1; 

            &::before{
                content: "";
                position: absolute;
                width: 36px;
                height: 36px;
                background: #B1CCEA;
                border-radius: 30px;
                left: -16px;
                top: -8px;
                z-index: -1;
            }         
        }
    }

    &__txt{
        font-size: 1.4rem;
        
        @include g.mq('md') {
            font-size: 1.6rem;
        }
    }

    &__pic{
        margin-bottom: 27px;
    }

    &__note{
        position: absolute;
        top: 13px;
        right: 13px;
        font-size: 1.1rem;
        color: #7D7D7D;
    }

    &__visual{
        margin-bottom: 15px;
    }
}


/*------------------------------------------
    正社員の平均年齢
------------------------------------------*/
.age{

    &__visual{        
        @include g.mq('md') {
            margin-top: 66px;
        }
    }

    &__number{
        font-size: 3.6rem;
        font-weight: bold;
        color: g.color('key');
        text-align: center;
        line-height: 1;
        
        @include g.mq('md') {
            font-size: 4.0rem;
        }

        span{
            font-family: g.font('en');
            font-size: 7.2rem;
            
            @include g.mq('md') {
                font-size: 11.8rem;
            }
        }
    }
    &__pic{
        text-align: center;
    }
}

/*------------------------------------------
    社員数＆正社員の男女比
------------------------------------------*/
.gender{
    $this: ".gender";

    &__visual{
        margin-bottom: 100px;
        
        @include g.mq('md') {
            margin-bottom: 30px;
        }
    }

    &__box{
        &:not(:last-child){
            margin-bottom: 10px;
            
            @include g.mq('md') {
                margin-bottom: 20px;
            }
        }

        &:nth-child(1){
            @include g.mq('md') {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    &__numtxt{
        text-align: center;
        font-size: 2.0rem;
        font-weight: bold;
        
        @include g.mq('md') {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 104px;
            height: 86px;
            font-size: 2.4rem;
            background: g.color('bg');
            margin-right: 20px;
        }
    }

    &__ratiotxt{
        text-align: center;
        font-size: 2.0rem;
        font-weight: bold;

        @include g.mq('md') {
            font-size: 2.4rem;
        }
    }

    &__number{
        font-size: 3.6rem;
        font-weight: bold;
        color: g.color('key');
        text-align: center;
        line-height: 1;
        
        @include g.mq('md') {
            font-size: 3.2rem;
        }

        span{
            font-family: g.font('en');
            font-size: 7.2rem;
            
            @include g.mq('md') {
                font-size: 9.3rem;
            }
        }
    }

    &__ratio{
        position: relative;
        display: flex;
        justify-content: center;
        max-width: 300px;
        margin: 0 auto;
        z-index: 1;

        &::before{
            content: "";
            position: absolute;
            top: -25px;
            left: 0;
            width: 108px;
            height: 176px;
            background: url(../img/data360/gender_man_sp.webp) center / contain no-repeat;
            z-index: -1;
            
            @include g.mq('md') {
                top: -9px;
                left: -92px;
                width: 152px;
                height: 95px;
                background: url(../img/data360/gender_man_pc.webp) center / contain no-repeat;
            }
        }

        &::after{
            content: "";
            position: absolute;
            top: -18px;
            right: 0;
            width: 80px;
            height: 178px;
            background: url(../img/data360/gender_woman_sp.webp) center / contain no-repeat;
            z-index: -1;
            
            @include g.mq('md') {
                top: -10px;
                right: -63px;
                width: 115px;
                height: 101px;
                background: url(../img/data360/gender_woman_pc.webp) center / contain no-repeat;
            }
        }
    }
}

/*------------------------------------------
    学生時代の専攻
------------------------------------------*/
.major{
    $this: ".major";

    &__visual{
        margin-bottom: 30px;

        @include g.mq('md') {
            display: flex;
            align-items: center;
            gap: 10px;
            max-width: 607px;
            margin: 0 auto 30px;
        }
    }

    &__ring{
        position: relative;
        left: 34px;
        width: 151px;
        height: 151px;
        margin: 0 auto 51px;
        
        @include g.mq('md') {
            left: 0;
            width: 175px;
            height: 175px;
            margin: 0 0 0 60px;
        }
        
        &::before {
            content: "";
            position: absolute;
            bottom: -21px;
            left: -68px;
            width: 94px;
            height: 109px;
            background: url(../img/data360/major_icon.webp) center / contain no-repeat;
            
            @include g.mq('md') {
                bottom: -10px;
                left: -73px;
                width: 81px;
                height: 93px;
            }
        }

        &::before{
            position: absolute;
            width: 94px;
            height: 109px;

        }

        &-txtbox{
            position: absolute;
            top: 15px;
            left: 50%;
            transform: translateX(-50%);
            
            @include g.mq('md') {
                top: 10px;
            }

        }

        &-txt{
            text-align: center;
            font-size: 2.4rem;
            color: #FFF;
            font-weight: bold;
            
            @include g.mq('md') {
                font-size: 3.2rem;
            }
        }

        &-number{
            position: relative;
            left: 5px;
            font-size: 2.4rem;
            font-weight: bold;
            color: #FFF;
            text-align: center;
            line-height: 0.8;
            
            @include g.mq('md') {
                left: 0;
                font-size: 3.1rem;
            }
    
            span{
                font-family: g.font('en');
                font-size: 7.2rem;
                
                @include g.mq('md') {
                    font-size: 8.9rem;
                }
            }
        }
    }

    &__box{
        @include g.mq('md') {
            flex-grow: 1;
        }

        &-txt{
            text-align: center;
            font-size: 1.8rem;
            line-height: 1.222;
            margin-bottom: 10px;
            font-weight: bold;
        }

        &-pic{
            display: flex;
            align-items: center;
            justify-content: center;

            @include g.mq('md') {
                height: 100px !important;
            }
        }
    }

    &__list{
        display: flex;
        flex-wrap: wrap;
        row-gap: 15px;
        
        @include g.mq('md') {
            gap: 0;
        }
    }

    &__item{
        width: 50%;
        
        @include g.mq('md') {
            width: calc(100% / 4);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        &:nth-of-type(1){
            #{$this}__box-pic{
                height: 100px;
                
                @include g.mq('md') {
                    height: auto;
                }
            }
        }

        &:nth-of-type(2){
            #{$this}__box-pic{
                height: 100px;
                
                @include g.mq('md') {
                    height: auto;
                }
            }
        }

        &:nth-of-type(3){
            #{$this}__box-txt{
                margin-top: 1.4em;
                margin-bottom: 16px;
                
                @include g.mq('md') {
                    margin-top: 0;
                }
            }
        }
    }
}

/*------------------------------------------
    有給休暇取得率
------------------------------------------*/
.vacationRate{

    &__visual{
        position: relative;
        width: 250px;
        margin: 35px auto 20px;

        @include g.mq('md') {
            width: 500px;
            margin: 35px auto 35px;
        }
    }

    &__pic{
        position: relative;
        width: 67px;
        z-index: 1;
        
        @include g.mq('md') {
            width: 121px;
        }

        &::before{
            content: "";
            position: absolute;
            top: -15px;
            left: -14px;
            width: 170px;
            height: 57px;
            background: url(../img/data360/vacationRate_01.webp) center / contain no-repeat;
            z-index: -1;
            
            @include g.mq('md') {
                top: -25px;
                left: -60px;
                width: 276px;
                height: 91px;
            }
        }
    }

    &__number{
        position: absolute;
        bottom: 0;
        right: 0;
        padding-bottom: 10px;
        font-size: 2.4rem;
        font-weight: bold;
        color: g.color('key');
        text-align: center;
        line-height: 0.8;
        z-index: 1;
        
        @include g.mq('md') {
            font-size: 5.5rem;
        }

        span{
            font-family: g.font('en');
            font-size: 7.2rem;
            
            @include g.mq('md') {
                font-size: 16.2rem;
            }
        }

        &::before{
            content: "";
            position: absolute;
            bottom: 0;
            right: -35px;
            width: 130px;
            height: 119px;
            background: url(../img/data360/vacationRate_02.webp) center / contain no-repeat;
            z-index: -1;
            
            @include g.mq('md') {
                width: 230px;
                height: 211px;
            }
        }
    }
}

/*------------------------------------------
    有給休暇平均取得日数
------------------------------------------*/
.holiday{

    &__visual{
        position: relative;
        width: 286px;
        margin: 0 auto 20px;
        
        @include g.mq('md') {
            width: 387px;
            margin: 0 auto 20px;
        }
    }

    &__date{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        font-size: 3.6rem;
        font-weight: bold;
        color: g.color('key');
        text-align: center;
        line-height: 0.8;
        z-index: 1;
        
        @include g.mq('md') {
            left: -20px;
            font-size: 5.5rem;
        }

        span{
            font-family: g.font('en');
            font-size: 9.0rem;
            letter-spacing: -7px;
            margin-right: 7px;
            
            @include g.mq('md') {
                font-size: 16.2rem;
            }
        }
    }

    &__circle{
        width: 156px;
        height: 156px;
        background: g.color('bg');
        border-radius: 100px;
        margin-left: auto;
        margin-right: 0;
        
        @include g.mq('md') {
            width: 176px;
            height: 176px;
        }
    }

    &__tit{
        padding-top: 18px;
        padding-bottom: 2px;
        text-align: center;
        font-size: 1.4rem;
        font-weight: bold;
    }

    &__number{
        position: relative;
        margin-bottom: 8px;
        font-size: 1.8rem;
        font-weight: bold;
        color: g.color('key');
        text-align: center;
        line-height: 0.8;
        z-index: 1;
        
        @include g.mq('md') {
            font-size: 2.3rem;
        }

        span{
            font-family: g.font('en');
            font-size: 4.8rem;
            
            @include g.mq('md') {
                font-size: 6.9rem;
            }
        }

        &::before{
            content: "";
            position: absolute;
            top: 0px;
            right: 24px;
            width: 18px;
            height: 19px;
            background: url(../img/data360/holiday.svg) center / contain no-repeat;
            z-index: -1;
            
            @include g.mq('md') {
            }
        }
    }

    &__note{
        line-height: 1;
        text-align: center;
        font-size: 1.1rem;
        
        @include g.mq('md') {
            font-size: 1.2rem;
        }
    }
    &__noteicon{
        line-height: 1;
        margin-top: -5px;
        text-align: center;
        color: g.color('key');
        font-weight: bold;
    }

}

/*------------------------------------------
    育児休暇後の就業復帰率
------------------------------------------*/
.return{
    &__visual{
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }
    
    &__canvas{
        flex: 0 0 auto;
        position: relative;
        width: 262px;
        height: 262px;

        @include g.mq('md') {
        }
    }

    &__number{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 3.6rem;
        font-weight: bold;
        font-family: g.font('en');
        color: g.color('key');
        text-align: center;
        line-height: 1;
        
        @include g.mq('md') {
            font-size: 3.1rem;
        }

        span{
            font-size: 7.2rem;
            
            @include g.mq('md') {
                font-size: 9.0rem;
            }
        }
    }

    &__pic{
        margin-left: -30px;
    }
}

/*------------------------------------------
    男性の育児休業取得率
------------------------------------------*/
.childcare{
    $this: ".childcare";

    &__visual{
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }
    
    &__bar{
        position: relative;
        flex: 0 0 auto;

        &-list{
            display: flex;
            gap: 15px;
            align-items: flex-end;
            
            @include g.mq('md') {
                gap: 32px;
            }
        }

        &-item{
            position: relative;
            height: 162px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            
            @include g.mq('md') {
                height: 268px;
            }

            &.is-2020{
                #{$this}__bar-graph{
                    height: 36px;
                    
                    @include g.mq('md') {
                        height: 60px;
                    }
                }
            }
            
            &.is-2021{
                #{$this}__bar-graph{
                    height: 78px;
                    
                    @include g.mq('md') {
                        height: 127px;
                    }
                }
            }
            
            &.is-2022{
                #{$this}__bar-graph{
                    height: 104px;
                    
                    @include g.mq('md') {
                        height: 169px;
                    }
                }
            }
        }

        &-graph{
            width: 43px;
            background: g.color('bg-dark');
            margin: 0 auto;
            
            @include g.mq('md') {
                width: 71px;
            }
        }

        &-number{
            position: absolute;
            top: -30px;
            left: 50%;
            transform: translateX(-50%);
            font-size: 0.9rem;
            font-weight: bold;
            font-family: g.font('en');
            color: g.color('key');
            text-align: center;
            line-height: 1;
            
            @include g.mq('md') {
                top: -56px;
            }
            
            @include g.mq('md') {
                font-size: 1.7rem;
            }
    
            span{
                font-size: 2.7rem;
                
                @include g.mq('md') {
                    font-size: 5.0rem;
                }
            }

            &-wrap{
                position: relative;
            }
        }

        &-year{
            font-size: 1.4rem;
            font-weight: bold;
            
            @include g.mq('md') {
                font-size: 2.4rem;
            }

            span{
                font-size: 1.4rem;
                
                @include g.mq('md') {
                    font-size: 1.8rem;
                }
            }
        }

        &-arrow{
            position: absolute;
            bottom: 35px;
            left: 50%;
            transform: translateX(-50%);
            width: 114px;
            
            @include g.mq('md') {
                bottom: 55px;
                width: 186px;
            }
        }
    }

    &__pic{
        margin-left: -10px;
        margin-bottom: 6px;
        
        @include g.mq('md') {
            margin-left: -13px;
            margin-bottom: 18px;
        }

        img{
            max-width: 128%;
            
            @include g.mq('md') {
                max-width: 100%;
            }
        }
    }
}

/*------------------------------------------
    平均残業時間
------------------------------------------*/
.overtime{
    &__visual{
        position: relative;
        width: 270px;
        margin: 65px auto 25px;
        z-index: 1;
        
        @include g.mq('md') {
            width: 500px;
            margin: 50px auto 25px;
        }

        &::before{
            content: "";
            position: absolute;
            top: -49px;
            left: -10px;
            width: 110px;
            height: 110px;
            background: url(../img/data360/overtime_time.webp) center / contain no-repeat;
            z-index: -1;
            
            @include g.mq('md') {
                top: -59px;
                left: -43px;
                width: 193px;
                height: 193px;
            }
        }

        &::after{
            content: "";
            position: absolute;
            top: -42px;
            right: 10px;
            width: 83px;
            height: 66px;
            background: url(../img/data360/overtime.webp) center / contain no-repeat;
            z-index: -1;
            
            @include g.mq('md') {
                top: -62px;
                right: -29px;
                width: 160px;
                height: 127px;
            }
        }
    }

    &__number{
        position: relative;
        margin-bottom: 8px;
        font-size: 3.6rem;
        font-weight: bold;
        color: g.color('key');
        text-align: center;
        line-height: 0.8;
        z-index: 1;
        
        @include g.mq('md') {
            font-size: 5.5rem;
        }

        span{
            font-family: g.font('en');
            font-size: 7.2rem;
            
            @include g.mq('md') {
                font-size: 16.2rem;
            }
        }
    }
}

/*------------------------------------------
    認定マーク
------------------------------------------*/
.certification{

    &__list{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: 0 23px;
        
        @include g.mq('md') {
            flex-wrap: nowrap;
            gap: 16px;
        }
    }

    &__item{
        &:nth-of-type(1){
            img{
                width: 123px;
                
                @include g.mq('md') {
                    width: 115px;
                }
            }
        }
        &:nth-of-type(2){
            img{
                width: 133px;
                
                @include g.mq('md') {
                    width: 144px;
                }
            }
        }
        &:nth-of-type(3){
            img{
                width: 111px;
                
                @include g.mq('md') {
                    width: 101px;
                }
            }
        }
    }

    &__visual{
        margin-bottom: 25px;
    }
}

/*------------------------------------------
    全国合格率
------------------------------------------*/
.pass{
    $this: ".pass";

    @include g.mq('md') {
        padding: 82px 40px 40px;
    }

    &__visual{
        position: relative;
        width: 290px;
        margin: 0 auto 20px;
        z-index: 1;
        
        @include g.mq('md') {
            width: 410px;
            margin: 0 auto 38px;
        }

        &::before{
            content: "";
            position: absolute;
            top: 13px;
            right: 0;
            width: 87px;
            height: 87px;
            background: url(../img/data360/pass.webp) center / contain no-repeat;
            z-index: -1;
            
            @include g.mq('md') {
                right: -10px;
                width: 126px;
                height: 126px;
            }
        }
    }

    &__box{
        display: flex;
        align-items: center;
        gap: 11px;
        
        &:not(:last-of-type){
            margin-bottom: 25px;
        }

        &.is-other{
            #{$this}__txt{
                margin-top: -5px;
                color: g.color('base');
            }

            #{$this}__number{
                color: #D6D8DB;
                font-size: 1.7rem;
                
                @include g.mq('md') {
                    font-size: 2.5rem;
                }
                
                span{
                    font-size: 5.0rem;
                    
                    @include g.mq('md') {
                        font-size: 7.3rem;
                    }
                }
            }
        }
    }

    &__txt{
        width: 72px;
        padding: 8px 2px;
        font-size: 1.2rem;
        color: g.color('key');
        background: g.color('bg');
        font-weight: bold;
        text-align: center;
        border-radius: 5px;
        line-height: 1.4;
        
        @include g.mq('md') {
            width: 100px;
            font-size: 1.8rem;
        }
    }

    &__number{
        position: relative;
        margin-bottom: 8px;
        font-size: 2.7rem;
        font-weight: bold;
        color: g.color('key');
        text-align: center;
        line-height: 0.8;
        z-index: 1;
        
        @include g.mq('md') {
            font-size: 4.0rem;
        }

        span{
            font-family: g.font('en');
            font-size: 7.2rem;
            
            @include g.mq('md') {
                font-size: 11.8rem;
            }
        }
    }
}

/*------------------------------------------
    新入社員研修
------------------------------------------*/
.training{

    &__visual{
        position: relative;
        display: flex;
        justify-content: center;
        
        @include g.mq('md') {
            flex-direction: row-reverse;
            align-items: center;
            justify-content: space-between;
            max-width: 599px;
            margin: 0 auto 35px;
        }
    }

    &__box{
        @include g.mq('md') {
            flex: 0 0 auto;
        }
    }

    &__tit{
        font-size: 2.0rem;
        font-weight: bold;
        text-align: center;
        margin-bottom: 20px;
        
        @include g.mq('md') {
            font-size: 2.4rem;
        }
    }

    &__pic{
        width: 132px;
        
        @include g.mq('md') {
            width: 240px;
        }
    }

    &__number{
        position: relative;
        margin-bottom: 8px;
        font-size: 3.6rem;
        font-weight: bold;
        color: g.color('key');
        text-align: center;
        line-height: 0.8;
        z-index: 1;
        
        @include g.mq('md') {
            font-size: 4.0rem;
        }

        span{
            font-family: g.font('en');
            font-size: 7.2rem;
            
            @include g.mq('md') {
                font-size: 11.8rem;
            }
        }

        &-wrap{
            @include g.mq('md') {
                display: flex;
                gap: 5px;
            }
        }
    }

    &__txt{
        margin: 20px 0 10px;
        font-size: 1.6rem;
        color: g.color('key');
        text-align: center;
        font-weight: bold;
        letter-spacing: -1px;
        
        @include g.mq('md') {
            margin: 15px 0 10px;
            font-size: 2.0rem;
            letter-spacing: -1px;
        }
    }
}

/*------------------------------------------
    年間研修回数
------------------------------------------*/
.trainingTime{

    &__visual{
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 245px;
        margin: -30px auto 25px;
        
        @include g.mq('md') {
            width: 550px;
            margin: -45px auto 35px;
        }
    }

    &__number{
        position: absolute;
        right: 0;
        top: 65%;
        transform: translateY(-50%);
        margin-bottom: 8px;
        font-size: 3.6rem;
        font-weight: bold;
        color: g.color('key');
        text-align: center;
        line-height: 0.8;
        z-index: 1;
        
        @include g.mq('md') {
            right: 19%;
            font-size: 5.5rem;
        }

        span{
            font-family: g.font('en');
            font-size: 7.2rem;
            
            @include g.mq('md') {
                font-size: 16.2rem;
            }
        }
    }

    &__pic{
        width: 93px;
        
        @include g.mq('md') {
            width: 131px;
        }
    }

    &__note{
        font-size: 1.1rem;
        font-weight: bold;
        position: absolute;
        right: -23px;
        bottom: -3px;
        
        @include g.mq('md') {
            right: 25px;
            bottom: 0;
            font-size: 1.8rem;
        }
    }
}

}