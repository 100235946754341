@use "../global" as g;
@use "sass:math";

/*------------------------------------------
	矢印
------------------------------------------*/
.arrow{
	position: relative;
	display: inline-block;
	width: 26px;
	height: 26px;
	background-color: g.color('accent');
	margin-left: 5px;
	border-radius: 50px;
	transition: .4s;
	
	@include g.mq('md') {
		width: 35px;
		height: 35px;
	}

	&::before,
	&::after{
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		margin: auto;
		content: "";
		vertical-align: middle;
		transition: .4s;
	}

	&::before{
		left: 50%;
		width: 10px;
		height: 1px;
		background: #FFF;
		transform: translateX(-50%);
		
		@include g.mq('md') {
			width: 12px;
		}
	}

	&::after{
		left: calc(50% + 2px);
		width: 4px;
		height: 4px;
		border-top: 1px solid #FFF;
		border-right: 1px solid #FFF;
		transform: translateX(-50%) rotate(45deg);
		
		@include g.mq('md') {
			width: 6px;
			height: 6px;
		}
	}

	&.is-02{
		width: 61px;
		height: 61px;
		background-color: #FFF;
		margin-left: 10px;
		
		@include g.mq('md') {
			width: 84px;
			height: 84px;
		}

		&::before{
			left: 50%;
			width: 14px;
			height: 1px;
			background: g.color('accent');
			
			@include g.mq('md') {
				width: 19px;
			}
		}

		&::after{
			left: calc(50% + 3px);
			width: 6px;
			height: 6px;
			border-top: 1px solid g.color('accent');
			border-right: 1px solid g.color('accent');
			
			@include g.mq('md') {
				width: 10px;
				height: 10px;
			}
		}
	}
}

a{
	&:hover{
		.arrow{
			opacity: 1;
			
			&.is-02{
				background-color: g.color('accent');
				
				&::before{
					left: calc(50% + 5px);
					background: #FFF;
				}
				&::after{
					left: calc(50% + 8px);
					border-top: 1px solid #FFF;
					border-right: 1px solid #FFF;
				}
			}
		}
	}
}
