@use "../global" as g;
@use "sass:math";

/*------------------------------------------
	ボタン
------------------------------------------*/
.btn-nomal {
	$this: ".btn-nomal";
	text-align: center;
	
	a,i{
		position: relative;
		display: inline-block;
		text-align: center;
		font-weight: bold;
		letter-spacing: 0;
		font-style: normal;
		min-width: 90%;
		background: #FFF;
		border: 1px solid g.color('accent');
		border-radius: 50px;
		padding: 15px 5px;
		color: g.color('accent');

		@include g.mq('sm') {
			font-size: 1.6rem;
		}

		@include g.mq('md') {
			min-width: 317px;
		}

		&:hover{
			background: g.color('accent');
			color: #FFF;

			#{$this}__arrow{
				&.is-02{
					background-color: transparent;
				}
			}
		}
	}

	& &__arrow{
		position: absolute !important;
		display: block;
		top: 0;
		bottom: 0;
		margin: auto;
		right: 25px;
		
		&.is-02{
			background-color: transparent;
			width: 25px;
			height: 25px;
		}
	}
}