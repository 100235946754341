/*******************************************
    会社の思い
*******************************************/
@use "sass:math";
@use "../global" as g;

#mission {


/*------------------------------------------
    cross
------------------------------------------*/
.cross{
    $this: ".cross";
    
    position: relative;
    padding: 40px 0 0;
    
    @include g.mq('lg') {
		padding: 75px 0 228px;
    }

    .inner{
        position: relative;
    }

    &__txtbox{
        position: relative;
        z-index: 1;
    }

    &__titpic{
        text-align: center;
		margin-bottom: 5px;
    }

    &__tit{
		margin-bottom: 18px;
        text-align: center;
        font-size: 2.0rem;
        color: g.color('key');
        line-height: 1.417;
        font-weight: bold;
        
        @include g.mq('md') {
			margin-bottom: 52px;
            font-size: 3.6rem;
        }
    }

    &__txt{
		text-align: center;
        line-height: 1.875;
		font-size: 1.6rem;
		
		@include g.mq('md') {
			font-size: 2.4rem;
			line-height: 2.208;
		}

		.is-accent{
			color: g.color('accent');
		}

		.is-key{
			color: g.color('key');
		}

		.is-big{
			font-size: 2.0rem;
			
			@include g.mq('md') {
				font-size: 3.2rem;
			}
		}
    }

    &__picitem{
		
		@include g.mq('lg') {
			margin-top: 0;
			position: absolute;
        }

        img{
            @include g.mq('lg-max') {
                width: 100%;
            }
        }

        &:nth-child(1){
			display: none;

            @include g.mq('lg') {
				display: block;
                top: -147px;
                left: -470px;
            }
            
            @include g.mq('xl') {
            }
        }

        &:nth-child(2){
			display: none;
			
            @include g.mq('lg') {
				display: block;
				top: 60px;
				right: -358px;
            }
            
            @include g.mq('xl') {
            }
        }

        &:nth-child(3){
			position: relative;
			left: -50%;
			margin-top: -20%;

			img{
				width: 215%;
				max-width: initial;
			}
			
			@include g.mq('lg') {
				display: none;
            }
        }
    }
}


/*------------------------------------------
    conts
------------------------------------------*/
.conts{
	$this: ".conts";
	position: relative;

	.inner{
		position: relative;
	}

	&__head{
		img{
			width: 100%;
		}
	}

	&__area{
		padding-top: 35px;
		padding-bottom: 60px;
		background-color: g.color('bg');
		
		@include g.mq('md') {
			padding-bottom: 180px;
		}
	}

	&__list{
		position: relative;
		padding-bottom: 100px;
		
		@include g.mq('md') {
			padding-bottom: 150px;
		}
	}

	&__item{
		position: relative;

		&:not(:last-of-type){
			margin-bottom: 90px;
			
			@include g.mq('md') {
				margin-bottom: 0;
			}
		}

		@include g.mq('md') {
			display: flex;
			gap: 40px;
			margin-top: 53px;
		}
		
		@include g.mq('lg') {
			gap: 125px;
		}
		
		&:nth-of-type(1){
			
			@include g.mq('md') {
				flex-direction: row-reverse;
			}

			&::before{
				//線背景
				content: "";
				position: absolute;
				width: 2258px;
				height: 660px;
				top: 610px;
				left: -318px;
				background: url(../img/mission/line_01.webp) center / 2258px no-repeat;
				z-index: 2;

				@include g.mq('md') {
					top: 355px;
					left: -587px;
				}
			}

			#{$this}__pic{
				
				@include g.mq('md') {
					margin-left: -100px;
				}

				&::before{
					//◯背景
					content: "";
					position: absolute;
					width: g.get_vw(745);
					height: g.get_vw(1241);
					top: g.get_vw(-600);
					left: -75%;
					background: url(../img/mission/conts_bg_01_sp.webp) center / contain no-repeat;
					z-index: -1;
					
					@include g.mq('md') {
						width: 1336px;
						height: 1216px;
						top: -218px;
						left: -234%;
						background: url(../img/mission/conts_bg_01_pc.webp) center / contain no-repeat;
					}
					
					@include g.mq('lg') {
						left: -75%;
					}
				}
			}

			#{$this}__tit{
				color: g.color('accent');

				&::after{
					content: "";
					position: absolute;
					top: -30px;
					left: 75px;
					width: 261px;
					height: 101px;
					background: url(../img/mission/conts_01_tit.webp) center / contain no-repeat;
					z-index: -1;
					
					@include g.mq('md') {
						top: -110px;
						left: 90px;
						width: 681px;
						height: 265px;
					}
				}
			}
		}
		&:nth-of-type(2){

			&::before{
				//線背景
				content: "";
				position: absolute;
				width: 2186px;
				height: 883px;
				top: 400px;
				left: -351px;
				background: url(../img/mission/line_02.webp) center / 2258px no-repeat;
				z-index: 1;

				@include g.mq('md') {
					display: none;
				}
			}

			#{$this}__pic{

				@include g.mq('md') {
					margin-right: -100px;
				}

				&::before{
					//◯背景
					content: "";
					position: absolute;
					width: g.get_vw(790);
					height: g.get_vw(820);
					top: g.get_vw(-300);
					left: -46%;
					background: url(../img/mission/conts_bg_02_sp.webp) center / contain no-repeat;
					z-index: -1;
					
					@include g.mq('md') {
						width: 1522px;
						height: 1214px;
						top: -75px;
						left: -36%;
						background: url(../img/mission/conts_bg_02_pc.webp) center / contain no-repeat;
					}
				}
			}

			#{$this}__tit{
				color: g.color('key');

				&::after{
					content: "";
					position: absolute;
					top: -39px;
					left: 205px;
					width: 112px;
					height: 122px;
					background: url(../img/mission/conts_02_tit.webp) center / contain no-repeat;
					z-index: -1;

					@include g.mq('md') {
						top: -110px;
						left: 80px;
						width: 681px;
						height: 265px;
					}
				}
			}
		}
	}

	&__pic{
		position: relative;
		z-index: 1;
		width: 90%;
		margin: 30px auto 0;
		
		@include g.mq('md') {
			flex: 0 0 auto;
			width: 300px;
			margin-top: 0;
		}
		
		@include g.mq('lg') {
			width: auto;
		}
	}

	&__tit{
		position: relative;
		margin-top: 30px;
		margin-bottom: 25px;
		font-size: 3.2rem;
		font-family: g.font('mincho');
		z-index: 2;
		
		@include g.mq('md') {
			margin-top: 152px;
			font-size: 4.6rem;
		}
	}

	&__txt{
		position: relative;
		line-height: 1.944;
		z-index: 2;
		
		.is-accent{
			font-size: 1.8rem;
			color: g.color('accent');
			
			@include g.mq('md') {
				font-size: 2.4rem;
			}
		}

		.is-key{
			font-size: 1.8rem;
			color: g.color('key');
			
			@include g.mq('md') {
				font-size: 2.4rem;
			}
		}
	}

	&__catchcopy{
		position: relative;
		padding: g.get_vw(122) 0 g.get_vw(195);
		z-index: 2;
		
		@include g.mq('md') {
			padding: 305px 0 143px;
		}

		&-wrap{
			position: relative;
			z-index: 1;

			&::before{
				//◯背景
				content: "";
				position: absolute;
				width: g.get_vw(540);
				height: g.get_vw(676);
				top: g.get_vw(-76);
				left: -82%;
				background: url(../img/mission/conts_bg_03_sp.webp) center / contain no-repeat;
				z-index: -1;
				
				@include g.mq('md') {
					width: 170%;
					height: 786px;
					top: -131px;
					left: -34%;
					background: url(../img/mission/conts_bg_03_pc.webp) center / 2448px no-repeat;
				}
			}
		}

		&-line{

			&::before{
				//線背景
				content: "";
				position: absolute;
				width: 2235px;
				height: 660px;
				top: -217px;
				left: -587px;
				background: url(../img/mission/line_02.webp) center / 2235px no-repeat;
				z-index: 1;
				
				@include g.mq('md') {
					top: -468px;
					left: -587px;
				}
			}

			&::after{
				//線背景
				content: "";
				position: absolute;
				width: 2126px;
				height: 1062px;
				top: -572px;
				left: -260px;
				background: url(../img/mission/line_03.webp) center / 2126px no-repeat;
				z-index: 1;
				
				@include g.mq('md') {
					width: 2235px;
					height: 1062px;
					top: -92px;
					left: -587px;
				}
			}
		}

		&-txt{
			position: relative;
			text-align: center;
			font-family: g.font('mincho');
			color: g.color('key');
			font-size: 2.0rem;
			z-index: 1;
			
			@include g.mq('md') {
				font-size: 3.6rem;
			}
		}

		&-item{
			position: absolute;
			z-index: -1;

			&:nth-of-type(1){
				top: 0;
				left: -7%;
				
				@include g.mq('md') {
					left: -250px;
				}
				
				img{
					@include g.mq('sm') {
						width: g.get_vw(182);
					}
				}
			}

			&:nth-of-type(2){
				bottom: 0;
				right: -12%;
				
				@include g.mq('md') {
					bottom: -27%;
					right: -446px;
				}

				@include g.mq('lg') {
					bottom: 0;
				}

				span{
					position: relative;
					display: block;

					&::before{
						display: none;

						@include g.mq('md') {
							//◯背景
							content: "";
							display: block;
							position: absolute;
							width: 271px;
							height: 271px;
							top: 120px;
							left: -49px;
							background: url(../img/mission/conts_bg_04_pc.webp) center / contain no-repeat;
						}
					}
				
					img{
						@include g.mq('sm') {
							width: g.get_vw(307);
						}
					}
				}
			}
		}
	}
}

}