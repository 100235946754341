@use "../global" as g;

/*------------------------------------------
	footer
------------------------------------------*/
.footer{
	position: relative;
	background: g.color('key');
	padding: 0 0 63px;
	z-index: 1;
	
	@include g.mq('md') {
		padding: 74px 0 63px;
	}
	
	@include g.mq('xl') {
		padding: 74px 0 0;
	}

	&__sns{
		padding: 17px 0;
		
		@include g.mq('md') {
			padding: 31px 0 25px;
		}

		&-list{
			display: flex;
			justify-content: flex-end;
		}
	}

	&__copyright {
		padding: 10px 0;
		font-family: g.font('tit');
		font-size: 1.2rem;
		letter-spacing: 0.1em;
		text-align: center;
		color: #FFF;
		border-top: 1px solid #FFF;
		
		@include g.mq('md') {
			font-size: 1.4rem;
			padding: 10px 0;
			margin-top: 0;
		}
	}
}