@use "../global" as g;

/*------------------------------------------
	header
------------------------------------------*/
.header {
	&__logo {
		position: fixed;
		top: 0;
		left: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 162px;
		height: 72px;
		background-color: #FFF;
		border-radius: 0 0 10px 10px;
		z-index: 100;
		
		@include g.mq('md') {
			left: 78px;
			width: 283px;
			height: 157px;
		}

		img{
			@include g.mq('sm') {
				width: 132px;
			}
		}
	}

	&__recruit-btn {
		position: fixed;
		bottom: 0;
		left: 0;
		z-index: map-get(g.$z-index, 'recruit-btn');
		width: 100%;

		@include g.mq('xl') {
			display: none;
		}

		> a {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 10px;
			height: 63px;
			font-size: 1.8rem;
			font-weight: 500;
			color: #fff;
			background-color: g.color('sub');

			&::before {
				content: '';
				width: 30px;
				height: 46px;
				background: url('../img/common/icon_recruit_btn.svg') no-repeat border-box center/cover;
			}
		}
	}
}