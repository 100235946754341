@use "../global" as g;

/*------------------------------------------
    下層メインビジュアル
------------------------------------------*/
.lowerMv {
	position: relative;

	&::after{
		content: "";
		position: absolute;
		right: 0;
		left: 0;
		bottom: -15px;
		margin: auto;
		width: 34px;
		height: 32px;
		background: url(../img/common/mv_icon.svg) center / contain no-repeat;

		@include g.mq('md') {
			bottom: 0;
			width: 105px;
			height: 99px;
		}
	}
	
	&__bg{
		
		img{
			width: 100%;
			min-height: 202px;
			object-fit: cover;
			
			@include g.mq('md') {
				min-height: 593px;
			}
		}
	}

	&__txtbox{
		margin-left: 36px;
		
		@include g.mq('md') {
			margin-left: 0;
		}

		&-wrap{
			position: absolute;
			width: 100%;
			height: 100%;
			margin: auto;
			top: 0;
			left: 0;
		}
	}

	.inner{
		position: relative;
		height: 100%;
	}

	&__txtbox{
		position: absolute;
		top: 58%;
		left: 0;
		transform: translateY(-50%);
		
		@include g.mq('md') {
			top: 50%;
		}
	}

	&__tit{
		font-size: 2.6rem;
		line-height: 0.923;
		color: #FFF;
		
		@include g.mq('md') {
			font-size: 6.2rem;
		}
		
		span{
			margin-top: 10px;
			font-size: 1.4rem;
			font-family: g.font('en');
			display: block;
			font-weight: 400;
			
			@include g.mq('md') {
				margin-top: 20px;
				font-size: 2.4rem;
			}
		}
	}
}