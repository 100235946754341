@use "../global" as g;

/*------------------------------------------
    追従エントリーボタン
------------------------------------------*/
.goentry {
	display: none;
	
	@include g.mq('xl') {
		display: block;
		position: fixed;
		right: 60px;
		bottom: 0;
		visibility: hidden;
		opacity: 0;
		text-align: center;
		z-index: map-get(g.$z-index, 'gotop');
		transition: 0.4s;
	}

	&.is-show {
		visibility: visible;
		opacity: 1;
	}
	
	a {
		img{
			&:nth-of-type(2){
				position: absolute;
				top: 0;
				left: 0;
				opacity: 0;
			}
		}
		
		&::before{
			content: "";
			position: absolute;
			top: -25px;
			right: -45px;
			width: 164px;
			height: 175px;
			background: url(../img/frontpage/fixed_entry_btn_fukidasi.webp) center / contain no-repeat;
			z-index: 1;
			transition: 0.4s;
		}

		&:hover{
			opacity: 1;

			img{
				&:nth-of-type(1){
					opacity: 0;
				}

				&:nth-of-type(2){
					opacity: 1;
				}
			}

			&::before{
				top: -45px;
			}
		}
	}
}