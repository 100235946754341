@use "../global" as g;

/*------------------------------------------
	ハンバーガーメニュー
------------------------------------------*/
.menubar {
	$bar-width-sp: 25px;
	$bar-width-pc: 40px;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	margin: auto;
	width: $bar-width-sp;
	height: 42px;

	@include g.mq('lg') {
		width: $bar-width-pc;
		height: 50px;
	}

	&__inner{
		position: relative;
		z-index: map-get(g.$z-index, 'header');
		display: inline-block;
		width: 100%;
		height: 100%;
		padding: 3px;
	}

	span {
		$paddingY: 10px; //バーの間隔をここで調整
		position: absolute;
		right: 0;
		display: inline-block;
		margin: auto;
		width: $bar-width-sp;
		height: 4px;
		background-color: g.color('key');
		border-radius: 4px;
		transition: all .4s;
		
		@include g.mq('lg') {
			width: $bar-width-pc;
		}

		&:nth-of-type(1) {
            top: $paddingY; 
        }

        &:nth-of-type(2) {
			top: 0;
			bottom: 0;
			width: 21px;
			
			@include g.mq('lg') {
				width: 33px;
			}
        }

        &:nth-of-type(3) {
            bottom: $paddingY; 
        }
	}
}