/*------------------------------------------
グロナビ
------------------------------------------*/
@use "sass:math";
@use "../global" as g;

.gnav {
	$this: &;
	$duration: 0.4s; //アニメーションの秒数
	position: fixed;
	top: 10px;
	right: 10px;
	$_size: 61px;
	width: $_size;
	height: $_size;
	background-color: #FFF;
	border-radius: 100vmax;
	border: 1px solid g.color('key');
	z-index: map-get(g.$z-index, 'gnav');
	cursor: pointer;
	transition: $duration;

	@include g.mq('lg') {
		$_size: 96px;
		width: $_size;
		height: $_size;
		top: 30px;
		right: 30px;
	}

	&__menubar {
		transition: $duration;
	}
	
	&__area {
		transition: $duration;
		visibility: hidden;
		opacity: 0;
		width: 258px;

		@include g.mq('xl-max') {
			position: fixed;
			top: 0;
			right: 0;
			z-index: map-get(g.$z-index, 'gnav-conts');
			height: 100%;
			background-color: #fff;
			transform: translateX(100%);
		}

		@include g.mq('md') {
			width: 350px;
		}

		@include g.mq('xl') {
			width: 307px;
		}

		&::before {
			@include g.mq('xl') {
				content: '';
				position: absolute;
				right: 0;
				bottom: -15px;
				width: 162px;
				height: 99px;
				background: url('../img/common/gnav_icon.svg') no-repeat border-box center/cover;
			}
		}
	}

	&__close {
		position: absolute;
		top: 9px;
		right: 13px;
		display: inline-flex;
		align-items: center;
		gap: 3px;
		font-family: g.font('en');
		font-size: 1.8rem;
		letter-spacing: 0;
		color: g.color('gray');

		@include g.mq('xl') {
			top: 39px;
			right: 50%;
			transform: translateX(50%);
		}
	}
	
	&__close-icon {
		@include g.close-icon(16px, 2px, g.color('gray'));
		margin-bottom: 2px;
	}

	&__list {
		display: grid;
		grid-template-columns: 1fr;
		gap: 5px;
		padding: 60px 20px 93px 25px;

		@include g.mq('xl') {
			gap: 7px;
			padding: 95px 28px 85px 40px;
		}
	}

	&__item {
		> a, > span {
			display: inline-flex;
			align-items: center;
			gap: 5px;
			font-size: 1.6rem;
			font-weight: bold;
			color: g.color('key');

			@include g.mq('xl') {
				gap: 9px;
			}

			&::before {
				content: '';
				width: 22px;
				height: 22px;
				background-position: center;
				background-repeat: no-repeat;
			}
		}

		// グロナビアイコンの大きさ
		$gnav_icon_map: (
			home: (
				sp: (width: 18px, height: 18px),
				pc: (width: 20px, height: 20px),
			),
			company: (
				sp: (width: 18px, height: 18px),
				pc: (width: 20px, height: 20px),
			),
			work: (
				sp: (width: 20px, height: 18px),
				pc: (width: 22px, height: 20px),
			),
			environment: (
				sp: (width: 22px, height: 18px),
				pc: (width: 24px, height: 20px),
			),
			recruit: (
				sp: (width: 19px, height: 18px),
				pc: (width: 21px, height: 19px),
			),
		);
		@each $name, $val in $gnav_icon_map  {
			&.is-#{$name} {
				> a, > span {
					&::before {
						background-image: url('../img/common/gnav_icon_#{$name}.svg');
						background-size: g.map-deep-get($val, sp, width) g.map-deep-get($val, sp, height);
						
						@include g.mq('md') {
							background-size: g.map-deep-get($val, pc, width) g.map-deep-get($val, pc, height);
						}
					}
				}
			}
		}
	}

	&__list-lv2 {
		display: grid;
		grid-template-columns: 1fr;
		gap: 2px;
		padding-left: 28px;

		@include g.mq('xl') {
			padding-left: 30px;
			margin-top: -4px;
		}
	}

	&__item-lv2 {
		line-height: 1.5;

		> a, > span {
			font-weight: bold;
			letter-spacing: 0;

			@include g.mq('xl') {
				font-size: 1.6rem;
			}

			span {
				display: block;
				font-size: 1.2rem;

				@include g.mq('xl') {
					font-size: 1.4rem;
				}
			}
		}
	}

	&__recruit {
		margin-top: 13px;

		@include g.mq('xl-max') {
			display: none;
		}

		a {
			display: flex;
			justify-content: center;
			align-items: center;
			$_height: 43px;
			height: $_height;
			border-radius: calc($_height / 2);
			width: 229px;
			margin: 0 auto;
			font-size: 1.8rem;
			font-weight: 500;
			color: #fff;
			background-color: g.color('sub');
		}
	}

	// アクティブ状態（SP）
	&.is-active-sp {
		#{$this}__area {
			visibility: visible;
			opacity: 1;
			transform: translateX(0);
		}
	}

	// コンテンツがウィンドウより大きければスクロール（SP）
	&.is-scroll {
		#{$this}__area {
			overflow-y: scroll;
		}
	}

	// アクティブ状態
	&.is-active {
		@include g.mq('xl') {
			border: none;
			cursor: auto;
		}

		#{$this}__area {
			@include g.mq('xl') {
				visibility: visible;
				opacity: 1;
				transition-delay: $duration;
			}
		}

		#{$this}__menubar {
			@include g.mq('xl') {
				opacity: 0;
				visibility: hidden;
				transition: none;
			}
		}
	}

	// グロナビ閉じる時
	&.is-hamburger {
		@include g.mq('xl') {
			transition-delay: $duration;
		}

		#{$this}__menubar {
			@include g.mq('xl') {
				transition-delay: $duration * 2;
			}
		}
	}
}